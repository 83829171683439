import React from "react";
import logo from "./convoco-logo-inicio.png";
import "./App.css";
import Conference from "./scriptConference";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a href="https://www.convoco.online">
          <img src={logo} className="App-logo" alt="logo" />
        </a>
        <p id="formacion">formación</p>
      </header>
      <main id="document">
        <Conference />
      </main>
    </div>
  );
}

export default App;
